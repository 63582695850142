// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { addMemberBlackIcon, addMemberIcon, groupSettingBlackIcon, groupSettingIcon, memberBlackIcon, memberIcon, sendMessageBlackIcon, sendMessageIcon } from "./assets";
import { IGroupsPermissionSettings } from "./GroupsController";

export const configJSON = require("./config");

interface Setting {
    icon: string;
    title: string;
    keyValue: string;
    subDescription?: string;
    isSelect: boolean;
}

interface RoleSettings {
    role: string;
    keyValue?: string;
    settings: Setting[];
    footerText: string;
}

interface ISettingsState {
    [key: string]: boolean | { [key: string]: boolean };
}

export interface Props {
    checked?: boolean;
    defaultSetting: IGroupsPermissionSettings;
    onChangeSetting: (groupSetting: IGroupsPermissionSettings) => void;
}

interface S {
    settingsState: ISettingsState
}

interface SS {

}

export default class GroupsController extends BlockComponent<
    Props,
    S,
    SS
> {
    listSettings = [
        {
            role: "members",
            keyValue: "membersPermissions",
            settings: [
                {
                    icon: this.props.checked ? groupSettingBlackIcon : groupSettingIcon,
                    title: "Edit Group Settings",
                    keyValue: "editSettings",
                    subDescription: "This includes the group name, icon, description, disappearing message timer, and the ability to pin, keep or unkeep messages",
                    isSelect: this.props.defaultSetting.membersPermissions.editSettings
                },
                {
                    icon: this.props.checked ? sendMessageBlackIcon : sendMessageIcon,
                    title: "Send Messages",
                    keyValue: "sendMessages",
                    isSelect: this.props.defaultSetting.membersPermissions.sendMessages
                },
                {
                    icon: this.props.checked ? addMemberBlackIcon : addMemberIcon,
                    title: "Add Other Members",
                    keyValue: "addMembers",
                    isSelect: this.props.defaultSetting.membersPermissions.addMembers
                }
            ],
            footerText: "Turning off these settings means that only group admins can perform this action."
        },
        {
            role: "admin",
            settings: [
                {
                    icon: this.props.checked ? memberBlackIcon : memberIcon,
                    title: "Approve new members",
                    keyValue: "adminApproveNewMembers",
                    isSelect: this.props.defaultSetting.adminApproveNewMembers

                }
            ],
            footerText: "When turned on, admins must approve anyone who wants to join this group."
        }
    ] as unknown as RoleSettings[]

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            settingsState: this.initializeSettingsState()
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    initializeSettingsState(): ISettingsState {
        return this.listSettings.reduce<ISettingsState>((acc, role) => {
            if (role.keyValue) {
                acc[role.keyValue] = role.settings.reduce<{ [key: string]: boolean }>(
                    (objRoleSetting, setting) => {
                        objRoleSetting[setting.keyValue] = setting.isSelect;
                        return objRoleSetting;
                    },
                    {}
                );
            } else {
                role.settings.forEach((setting) => {
                    acc[setting.keyValue] = setting.isSelect;
                });
            }
            return acc;
        }, {});
    }


    handleSwitchChange = (roleKeyValue: string, settingKeyValue: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        this.setState((prevState) => {
            const updatedState = { ...prevState.settingsState };

            if (roleKeyValue in updatedState && typeof updatedState[roleKeyValue] === 'object') {
                const nestedSettings = updatedState[roleKeyValue] as { [key: string]: boolean };
                nestedSettings[settingKeyValue] = event.target.checked;
            } else {
                updatedState[settingKeyValue] = event.target.checked;
            }

            return { settingsState: updatedState };
        }, () => {
            const listSetting = { ...this.state.settingsState } as unknown;
            this.props.onChangeSetting(listSetting as IGroupsPermissionSettings)
        });
    };

}

// Customizable Area End
