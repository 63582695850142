import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
const uuId=require('device-uuid')
import  jwt_decode  from "jwt-decode";
export interface ILoginUserResponseAttributes{
  id: number,
  full_name: string,
  user_name: string,
  email: string,
  full_phone_number: string,
  country_code: string|null,
  is_early_adopter: boolean,
  date_of_birth: string,
  gender: string |null,
  profile_photo: string|null,
  cover_photo: string |null,
  phone_number: string,
  bio: string|null,
  location: string|null,
  website: string|null,
  occupation: string|null,
  created_at: string,
}

export interface IMeta{
  token:string,
  refresh_token:string
}

export interface ILoginUserResponse {
  data: {
    id: string,
    type: string,
    attributes:ILoginUserResponseAttributes
  };
  meta:IMeta

}
interface CredentialResponse {
  credential?: string;
  select_by?: 'auto' | 'user' | 'user_1tap' | 'user_2tap' | 'btn' | 'btn_confirm' | 'brn_add_session' | 'btn_confirm_add_session';
  clientId?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  isPasswordVisible: boolean;
  apiError: string | null;
  loginPageLoading: boolean;
  isLightMode: boolean;
  validationError:string;
  isButtonDisable:boolean
  userGoogle:CredentialResponse,
  profileGoogle:{firstName:string,lastName:string,email:string,photo:string},
  error:string



  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  loginApiCallID: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  googleAccountApiCallId:string="";
  loggedInApiCallIdSignup:string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      isPasswordVisible: false,
      apiError: null,
      loginPageLoading: false,
      isLightMode: false,
      validationError:'',
      isButtonDisable:false,
      userGoogle:{credential:'',clientId:'',select_by:'auto'},
      profileGoogle:{firstName:'',lastName:'',email:'',photo:''},
      error:""



    };

    this.emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  navigateToDashboard(route:string) {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    switch (route) {
      case 'date_of_birth':
        this.navigateToScreens("DateOfBirth");
        break;
      case 'user_name':
          this.navigateToScreens("OTPInputAuth", 'user');
          break;
  
      case 'choose_profile':
        this.navigateToScreens("OTPInputAuth",'profile');
        break;
  
      case 'select_category':
          this.navigateToScreens("Categories");
        break;
  
      case 'select_sub_category':
        this.navigateToScreens("Categories",'sub');
        break;
  
      case 'choose_subscription':
          this.navigateToScreens("Customisableusersubscriptions");
          break;
  
      case 'become_an_owner':
          this.navigateToScreens("BecomeOwner");
        break;
  
      case 'connect_social_account':
        this.navigateToScreens("Followers");
        break;
  
      case 'follow_account':
          this.navigateToScreens("Followers",'follow');
          break;
      case 'completed':
            this.navigateToScreens("LandingPage");
          break;    
  
      default:
        break;
    }
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  navigateToScreens=(route:string,profile?:string)=>{
    const messages: Message = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    messages.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    const raiseMessages: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessages.addData(getName(MessageEnum.SessionResponseData), {
      active:profile,
    });
    messages.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessages);
    this.send(messages);
  }

  handleLogin = (values: {email: string; password: string}) => {
    this.setState({
      loginPageLoading: true,
    });
    const deviceId=new uuId.DeviceUUID().get()
    const deviceName=window.navigator.platform
    const headers = {
      "content-type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    let httpBody = {};

    if (this.isEmail(values.email) && values.email.slice(0,1)!=='@') {
      const emailRegex = configJSON.emailRegex;
      if (emailRegex.test(values.email)) {
        this.setState({
          validationError: "",
          apiError: ""
        })
        httpBody = {
        data: {
          type: "email_account",
          attributes: {
            email: values.email,
            password: values.password,
            device_name:deviceName,
            device_id:deviceId
          },
        },
      }

        
      } else {
        this.setState({
          validationError: "Incorrect Email id",
          apiError: ""
        })
      }
    } else if (this.isPhoneNumber(values.email)) {
      this.setState({
        validationError: "",
        apiError: ""
      })

      httpBody = {
        data: {
          type: "email_account",
          attributes: {
            full_phone_number: values.email,
            password: values.password,
            device_name:deviceName,
            device_id:deviceId
          },
        },
      }   
    } else {
        httpBody = {
        data: {
          type: "email_account",
          attributes: {
            user_name: values.email,
            password: values.password,
            device_name:deviceName,
            device_id:deviceId
          },
        },
      }
    }
  

    this.loginApiCallID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.loginEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleLoginResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.setState({
      loginPageLoading: false,
    });
    if (responseJson?.meta?.token) {
      this.saveLoggedInUserData(responseJson);
      this.handleMultipleSwitchUser(responseJson)
      this.sendLoginSuccessMessage();
      this.navigateToDashboard(responseJson.data.attributes.signup_state);
      localStorage.setItem('cometChatToken', responseJson?.meta?.auth_token)
    } else if (responseJson?.errors && responseJson?.errors?.length > 0) {
      this.setState({
        apiError: responseJson?.errors[0],
        validationError: ""
      });
    }
  }

  handleMultipleSwitchUser=async(responseJson: ILoginUserResponse)=>{
    let getMultiUser:Array<{refresh_token:string,user_id:string,token:string,profile_photo:string,username:string,fullName:string}>=[]
    const payload = {
      refresh_token:responseJson?.meta?.refresh_token as string,
      user_id:responseJson?.data?.id as string,
      token:responseJson?.meta?.token as string,
      profile_photo:responseJson?.data?.attributes?.profile_photo as string,
      username:responseJson?.data?.attributes?.user_name as string,
      fullName:responseJson?.data?.attributes?.full_name as string
    }; 

    setStorageData("selectedUser",JSON.stringify(payload)) 
     const userData = await getStorageData("multi_user",true);
     getMultiUser = userData ?? []
     if(getMultiUser.length>0){
       const  findUser = getMultiUser.find((item)=>item.user_id===payload.user_id)
       if(findUser){
       const multi_user = getMultiUser.map((item)=>{
          if(item.user_id===payload.user_id){
            return payload
          }else{
            return item
          }
       })
       setStorageData("multi_user",JSON.stringify(multi_user))
       }else{
         getMultiUser.push(payload)
        setStorageData("multi_user",JSON.stringify(getMultiUser))
       }
     }else{
        getMultiUser.push(payload)
       setStorageData("multi_user",JSON.stringify(getMultiUser))
     }
  }

  goToSignUpFromLogin() {
    const message: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleApiResponses = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    switch (apiRequestCallId) {
      case this.validationApiCallId:
        const arrayholder = responseJson?.data;
        if (arrayholder && arrayholder.length !== 0) {
          let regexData = arrayholder[0];
          if (regexData && regexData.email_validation_regexp) {
            this.emailReg = new RegExp(regexData.email_validation_regexp);
          }
        }
        break;
      case this.apiEmailLoginCallId:
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          this.saveLoggedInUserData(responseJson);
          this.sendLoginSuccessMessage();
          this.openInfoPage();
        } else {
          this.parseApiErrorResponse(responseJson);
          this.sendLoginFailMessage();
        }
        this.parseApiCatchErrorResponse(errorReponse);
        break;
    
      case this.loginApiCallID:
        this.handleLoginResponse(message);
        break;
    
      default:
        break;
    }    
  }

  isEmail = (value: string) => {
    const atSymbolRegex = /@/;
    return atSymbolRegex.test(value);
  };

  isPhoneNumber = (value: string) => {
    const numbersRegex = /^(\+?\d{1,4})?\d{7,15}$/;
    return numbersRegex.test(value.replace(/[\s()\-]/g, ''));
  };

  handleText=(event:React.ChangeEvent<HTMLInputElement>,handleChange:(event:{target:{name:string,value:string}})=>void)=>{
    if(event.target.value){
     this.setState({
       apiError:'',
       validationError: event.target.name==configJSON.emailName?'':this.state.validationError
     })
    }
    
    handleChange(event)
   }

  toggleShowHideIcon=()=>{
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  }

  navigateToForgotPassword=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ForgotPassword"
    );
    this.send(message);
  }

  getLoggedInUserSignup=(responseJson:any)=>{
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      token: responseJson.meta.token,
    };

    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loggedInApiCallIdSignup = getMsg.messageId;

    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loggedInEndPoint
    );

    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);

  }


  createUserWithGoogle=(token:string)=>{
    const deviceNewId=new uuId.DeviceUUID().get()
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const apiData = {
     attributes:{id_token:token,platform:"google",device_id:deviceNewId}
    };
  
    const httpBody = {
      data: apiData,
    };
  
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.googleAccountApiCallId = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleAuthEndPoint
    );
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
  
  }

  fetchProfile=()=>{
    const { userGoogle } = this.state;
    const userToken=userGoogle?.credential||''
    this.createUserWithGoogle(userToken)
    const decode:{family_name:string,given_name:string,email:string,photo:string} = jwt_decode(userToken);
    this.setState({profileGoogle:{firstName:decode?.family_name,lastName:decode?.given_name,email:decode?.email,photo:decode?.photo}})
  }

  handleLoginSuccess = (credentialResponse:CredentialResponse) => {
    this.setState({userGoogle: credentialResponse },()=>this.fetchProfile());
    window.localStorage.setItem("googleCredentialResponse",JSON.stringify(credentialResponse))  
  };
  
  handleLoginError = () => {
   this.setState({error:'Login error'})
  };
  handleMultiUser = async (responseJson: any) => {
    let showMultiUser: Array<{
      refresh_token: string | undefined;
      user_id: string;
      token: string | undefined;
      profile_photo: string;
      username: string;
      fullName: string;
    }> = [];
    const sendData = {
      refresh_token: responseJson?.meta && responseJson?.meta?.refresh_token,
      user_id: responseJson.data.id,
      token: responseJson?.meta && responseJson?.meta?.token,
      profile_photo: responseJson.data.attributes.profile_photo as string,
      username: responseJson.data.attributes.user_name,
      fullName: responseJson.data.attributes.full_name,
    };
    setStorageData("selectedUser", JSON.stringify(sendData));
    const usersData = await getStorageData("multi_user", true);

    showMultiUser = usersData ?? [];
    if (showMultiUser.length > 0) {
      const findUsers = showMultiUser.find(
        (item) => item.user_id === sendData.user_id
      );
      if (findUsers) {
        const multi_user = showMultiUser.map((item) => {
          if (item.user_id === sendData.user_id) {
            return {...item,username:sendData.username,profile_photo:sendData.username};
          } else {
            return item;
          }
        });
        setStorageData("multi_user", JSON.stringify(multi_user));
      } else {
        showMultiUser.push(sendData);
        setStorageData("multi_user", JSON.stringify(showMultiUser));
      }
    } else {
      showMultiUser.push(sendData);
      setStorageData("multi_user", JSON.stringify(showMultiUser));
    }
  };

  handleApiRespOfLogin = (responseJson:any)=>{
    setStorageData('authToken',responseJson.meta.token)
    this.getLoggedInUserSignup(responseJson)
    this.handleMultiUser(responseJson)
    
  }

  handleAppleLoginSuccess = (credentialResponse:any) => {
    console.log("apple accounts login resp:", credentialResponse); 
    const updatedResp = {
      ...credentialResponse,
      meta: {
        token: credentialResponse?.meta?.tokens?.login, // Correctly accesses the login token
        refresh_token: credentialResponse?.meta?.tokens?.refresh, // Correctly accesses the refresh token
      },
    };
    console.log("updated resp",updatedResp);
    
    this.handleApiRespOfLogin(updatedResp)
  };
 

  getUserApiSignup=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJsonSuccess = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJsonSuccess && !responseJsonSuccess?.errors) {
        if (apiRequestCallId === this.loggedInApiCallIdSignup) {
          const userDetails: {
            profilePhoto: string | null,
            followingCount: number,
            followersCount: number, userSubscription: string,
            userOwnership: boolean, categories: boolean,
            subCategories: boolean,
            dateOfBirth:any,
          } = {
            profilePhoto: responseJsonSuccess.data.attributes.profile_photo,
            followingCount: responseJsonSuccess.data.attributes.following_count,
            followersCount: responseJsonSuccess.data.attributes.followers_count,
            userSubscription: responseJsonSuccess.data.attributes.user_subscription,
            userOwnership: responseJsonSuccess.data.attributes.user_ownership,
            categories: responseJsonSuccess.data.attributes.user_categories,
            subCategories: responseJsonSuccess.data.attributes.user_sub_categories,
            dateOfBirth:responseJsonSuccess.data.attributes.date_of_birth,

          }
          this.navigateToDashboard(responseJsonSuccess.data.attributes.signup_state)
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else {
        this.setState({ apiError: responseJsonSuccess?.errors[0] })
      }
    }
  }


  navigateToUserName=(responseJson:any)=>{
    const messageRequest: Message = new Message(getName(MessageEnum.NavigationMessage));
    messageRequest.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    messageRequest.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OTPInputAuth"
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {screen:3,response:responseJson});
    messageRequest.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageRequest);
  }
  getProfileGoogle=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson?.errors) {
        if (apiRequestCallId === this.googleAccountApiCallId) {
          setStorageData('authToken',responseJson.meta.token)
          this.navigateToDashboard(responseJson.data.attributes.signup_state)
          this.handleMultiUser(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else {
        this.setState({ apiError: responseJson.errors[0] })
      }
    }

  }


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponses(message);
    }
    this.getUserApiSignup(message)
    this.getProfileGoogle(message)


    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
