import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Popover,
  withStyles,
  createStyles,
  styled,
  createTheme,
} from "@material-ui/core";
import { backArrowDark, backArrowWhite, callDark, callLight, emojiIcon, imageFileIcon, ownerShipIcon, profile, searchDark, searchWhite, sendIcon, threeDots, threeDotsLight, verifyIcon } from "./assets";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatMessages, MessageListConfiguration } from "@cometchat/chat-uikit-react";
import MenuOptions from "../../../components/src/Chats/MoreOptions.web"
import { dateStyle, getConversationAvatar, messageListStyle, TAny } from "../../../components/src/CometChat";
// Customizable Area End

import ViewChatController, { configJSON, IMessage, Props } from "./ViewChatController";

// Customizable Area Start
import clsx from "clsx";
import { CloseOutlined } from "@material-ui/icons";
import { verifyImg } from "../../../components/src/utils";
import MuteNotifiactionsModal from "../../../components/src/Chats/MuteNotificationsModal.web";
import DisappearingMessagesModal from "../../../components/src/Chats/DisappearingMessagesModal.web";
import BlockModal from "../../../components/src/Chats/BlockModal.web";
import ClearChatModal from "../../../components/src/Chats/ClearChatModal.web";

interface Metadata {
  username?: string;
  adminApproveNewMembers?: boolean,
  membersPermissions?: {
    addMembers: boolean,
    editSettings: boolean,
    sendMessages: boolean
  };
  user_subscription?: string;
  user_ownership?: boolean
}

interface IConversation {
  avatar?: string;
  name: string;
  metadata: Metadata;
  icon?: string;
  scope?: string;
  guid?: string;
}
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderEmotionButton = () => {
    const { emojiAnchorEl } = this.state;
    const open = Boolean(emojiAnchorEl);
    const id = open ? 'emoji-popover' : undefined;

    return (
      <Box>
        <IconButton data-testid="emoji-picker-button" onClick={this.handleClickEmoji}>
          <img src={emojiIcon} />
        </IconButton>
        <EmojiPopover
          id={id}
          open={open}
          anchorEl={emojiAnchorEl}
          onClose={this.handleCloseEmoji}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ marginTop: -66 }}
        >
          <Picker
            data-testid="emoji-picker"
            theme="dark"
            onSelect={this.handleSelect}
            autoFocus
          />
        </EmojiPopover>
      </Box>
    )
  }

  handleConditionFunction = (condition: TAny, trueCondition: TAny, falseCondtion: TAny) => {
    return condition ? trueCondition : falseCondtion
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const initialMetadata = {
      username: "",
      adminApproveNewMembers: false,
      membersPermissions: {
        addMembers: true,
        editSettings: true,
        sendMessages: true
      },
      user_subscription: "",
      user_ownership: false
    };
    const { checked, conversation, classes } = this.props;
    const { avatar = "", name = "", metadata = initialMetadata, icon = "", guid = "", scope = "" } = conversation as unknown as IConversation;
    const { username = "", membersPermissions, user_subscription = "", user_ownership = false } = metadata;
    const avatarMessageInformation = avatar || icon;
    const { isTypingMessage } = this.state;

    const isCheckCanSendMessage = !guid || (scope === "admin" || membersPermissions?.sendMessages);

    return (
      <>
        <MuteNotifiactionsModal data-testid="muteModal" isOpen={this.state.muteNotificationsModal} 
        checked={!checked} value="" 
        onMute={()=>{}} onClose={this.handleToggleMuteModal}
        />
        <DisappearingMessagesModal data-testid="messagesModal" isOpen={this.state.disappearingNotificationModal}
         value="" checked={!checked} 
         onSubmit={()=>{}} onClose={this.handleToggleDisappearingModal} />
        <BlockModal data-testid="blockModal" isOpen={this.state.blockUserModal} 
        checked={!checked} userName={conversation.getName()}
        onClose={this.handleToggleBlockModal} onSubmit={()=>{}} isBlock={false}/>
        <ClearChatModal data-testid="clearChatModal" 
        isOpen={this.state.clearChatModal} checked={!checked} 
        onSubmit={()=>{}} onClose={this.handleToggleClearChatModal}/>

      <Box data-test-id="viewChatId" className={classes.container}>
        <Box className={classes.chatHeader}>
          <Box className={classes.chatHeaderItem}>
            <span className={classes.iconWrapper} data-test-id="backConversation" onClick={this.props.onCloseSelectedConversation}>
              <img className={classes.icon} src={this.handleConditionFunction(checked, backArrowDark, backArrowWhite)} />
            </span>
            <img
              className={classes.headerProfileImg}
              style={{ border: this.handleConditionFunction(checked, "1px solid #D1B7F5", "1px solid #FFFF") }}
              src={getConversationAvatar(conversation) || profile}
              alt="conversation-avatart"
            />
            <Box className={classes.headerConversation}>
              <Typography className={classes.headerProfileName}>{conversation.getName()}</Typography>
              <Typography className={classes.headerSubContent}>{isTypingMessage && "Typing..."}</Typography>
            </Box>
          </Box>
          <Box className={classes.chatHeaderItem}>
            <span className={classes.iconWrapper}><img className={classes.icon} src={checked ? callDark : callLight} alt="" /></span>
            <span className={classes.iconWrapper}><img className={classes.icon} src={checked ? searchDark : searchWhite} alt="" /></span>
            <span className={classes.iconWrapper}>
            <MenuOptions theme={checked?commonLightTheme:commonDarkTheme} 
              checked={checked}
              data-testid="menuBtn"
              menuItems={[
                {id:1,name:"Media,Docs",action:()=>{}},
                {id:2,name:"Mute Notifications",action:()=>{this.handleToggleMuteModal()}},
                {id:3,name:"Disappearing messages",action:()=>{this.handleToggleDisappearingModal()}},
                {id:4,name:"Report",action:()=>{}},
                {id:5,name:"Unblock",action:()=>{this.handleToggleBlockModal()}},
                {id:6,name:"Clear Chat",action:()=>{this.handleToggleClearChatModal()}},
               ]}
            />
            </span>
          </Box>
        </Box>
        <Box className={classes.messagesContainer}>
          <Box className={`${classes.messageInformation} ${clsx(this.state.listMessage.length && "displayNone")}`}>
            <Box className={classes.messageInformation__avatar}>
              <img alt="ava" src={avatarMessageInformation || profile} width={90} height={90} />
            </Box>
            <Box className={classes.messageInformation__info}>
              <Typography component="p" className={`messageInformation__infoName ${clsx(checked && classes.lightThemeText)}`}>
                {name}
              </Typography>
              <Box className="messageInformation__infoBadge">
                <Box>
                  <img alt="i" src={verifyImg(user_subscription)} width={14} height={14} />
                </Box>
                <Box>
                  {user_ownership && <img alt="i" src={ownerShipIcon} width={14} height={14} />}
                </Box>
              </Box>
            </Box>
            <Box className={classes.messageInformation__username}>
              <Typography component="p" className={clsx(checked && classes.lightThemeText)}>
                {username}
              </Typography>
            </Box>
          </Box>
          {conversation instanceof CometChat.User ? (
            <CometChatMessages
              user={conversation}
              messageListConfiguration={new MessageListConfiguration({
                templates: this.state.template,
                messageListStyle: messageListStyle,
                dateSeparatorStyle: dateStyle,
                readIcon: ""
              })}
              messagesStyle={{ background: "transparent" }}
              hideDetails={false}
              hideMessageHeader={true}
              hideMessageComposer={true}
            />
          ) : (
            <CometChatMessages
              group={conversation}
              messageListConfiguration={new MessageListConfiguration({
                templates: this.state.template,
                messageListStyle: messageListStyle,
                dateSeparatorStyle: dateStyle,
                readIcon: ""
              })}
              messagesStyle={{ background: "transparent" }}
              hideDetails={false}
              hideMessageHeader={true}
              hideMessageComposer={true}
            />
          )}
        </Box>

        <Box className={classes.footerWrapper}>
          {
            !isCheckCanSendMessage && <Box className={`messages ${checked && "lightTheme"}`}>
              <Typography className={classes.message__text} component="p">
                Only Group <Typography component="span">admins</Typography> can send messages.
              </Typography>
            </Box>
          }
          {
            !!isCheckCanSendMessage && <Box className={`sendMessageContainer ${clsx(checked && "lightTheme")}`}>
              <TextField
                data-testid="messageInput"
                variant="outlined"
                placeholder="Message..."
                className={classes.messageField}
                fullWidth
                data-test-id="messageInputId"
                value={this.state.message}
                onChange={this.handleMessageChange}
                onBlur={this.handleBlurMessage}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {this.renderEmotionButton()}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <Box className="pointer" onClick={this.handleSelectFileIcon}>
                      <img src={imageFileIcon} width={24} height={24} alt="i" />
                      <input
                        type="file"
                        ref={this.fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => this.handleSelectFileInput(e)}
                        id="fileUpload"
                        accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx, .xls, .xlsx, image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                    </Box>
                  )
                }}
              />
              <Button
                className={classes.sendMessageBtn}
                onClick={this.handleClickSendMessage}
                disabled={!this.state.message.trim().length && !this.state.listFileUpload.length}
              >
                <img src={sendIcon} alt="" />
              </Button>

              <Box className="filesSelectedContainer">
                {this.state.listFileUpload.map(fileUpload => (
                  <Box key={fileUpload.name} className="filesSelected">
                    <Typography component={"p"}>
                      {fileUpload.name}
                    </Typography>
                    <IconButton data-test-id="removeFileSelected" onClick={() => this.handleRemoveFileUpload(fileUpload.name)}>
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          }

        </Box>
      </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = createStyles({
  container: {
    height: '100%',
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "& .displayNone": {
      display: "none"
    },
    '& .cc-list__content': {
      padding: '0 !important',
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "& .pointer": {
      cursor: "pointer"
    }
  },
  chatHeader: {
    paddingTop: '15px',
    display: "flex",
    justifyContent: "space-between"
  },
  chatHeaderItem: {
    display: "flex",
    alignItems: "center",
    overflow:"hidden",
  },
  iconWrapper: {
    width: "24px",
    height: "24px",
    padding: '10px 12px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  headerProfileImg: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
  headerConversation: {
    marginLeft: '12px',
  },
  headerProfileName: {
    fontSize: "24px",
    fontWeight: 800,
    fontFamily: "Manrope",
    textTransform: "capitalize",
  },
  headerSubContent: {
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: 12,
    color: "#757575"
  },
  messagesContainer: {
    height: "calc(100vh - 205px)",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    padding: "0 16px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  messageInformation: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "center"
  },
  messageInformation__avatar: {
    "& img": {
      border: "1px solid #F0E5FF",
      borderRadius: 100,
      marginBottom: 10
    }
  },
  messageInformation__info: {
    display: "flex",
    gap: 4,
    "& .messageInformation__infoName": {
      fontFamily: "Manrope",
      fontWeight: 600,
      fontSize: 16,
      color: "#FFFFFF"
    },
    "& .messageInformation__infoBadge": {
      display: "flex",
      gap: 2,
      alignItems: "center"
    }
  },
  messageInformation__username: {
    "& p": {
      fontFamily: "Manrope",
      fontWeight: 500,
      fontSize: 14,
      color: "#FFFFFF"
    }
  },
  messageProfileWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    alignItems: "center"
  },
  messageProfileImg: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
  },
  messageProfileName: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Manrope",
    textTransform: "capitalize",
  },
  footerWrapper: {
    display: "flex",
    gap: "10px",
    position: "relative",
    flex: 1,
    "& .sendMessageContainer": {
      width: "100%",
      display: "flex",
      gap: 10,
      margin: "0 20px"
    },
    "& .messages.lightTheme": {
      background: "#FFFFFF",
      boxShadow: "0px -2px 4px 0px #00000014",
      borderImageSource: "linear-gradient(264.77deg, rgba(255, 255, 255, 0.18) -7.36%, rgba(255, 255, 255, 0) 95.13%)",
      "& p": {
        color: "#222222"
      }
    },
    "& .messages": {
      background: "#96969633",
      backdropFilter: "blur(39px)",
      border: "1px solid",
      borderImageSource: "linear-gradient(264.77deg, rgba(255, 255, 255, 0.18) -7.36%, rgba(255, 255, 255, 0) 95.13%)",
      width: "100%",
      marginTop: "20px",
      height: "calc(100% - 20px)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "12px",
        color: "#FFFFFF",
        "& span": {
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: "12px",
          background: "linear-gradient(90deg, #3858E3, #BA05F7)",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent"
        }
      }
    },
    "& .filesSelectedContainer": {
      position: "absolute",
      top: "-50px",
      display: "flex",
      alignItems: "center",
      gap: 8,
      maxWidth: "100%",
      overflowX: "scroll",
      "& .filesSelected": {
        width: "100%",
        background: "#cccccc",
        padding: "8px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        gap: 4,
        borderRadius: "8px",
        "& p": {
          fontFamily: 'Manrope',
          fontWeight: 500,
          fontSize: '14px',
          color: "#6B6B6B",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& button": {
          padding: 2
        }
      }
    }
  },
  messageField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: "10px",
      '&.Mui-focused fieldset': {
        border: "1px solid #7A2BF5",
      },
    },
    width: "100%"
  },
  sendMessageBtn: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: "56px",
    width: "56px",
    borderRadius: "16px",
    padding: '10px',
    "&:hover": {
      background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    },
    "&.Mui-disabled": {
      background: "linear-gradient(-45deg, #8f86ed, #d35bf8)"
    },
  },
  messageItemContainer: {
    display: 'flex',
    margin: '10px 0'
  },
  messageItemWrapper: {
    maxWidth: '60%',
    padding: '12px',
    borderRadius: '10px',
    boxShadow: '0 0 5px rgba(0,0,0,0.1)',
    display: 'flex',
    gap: '12px',
    alignItems: 'flex-end',
  },
  messageTextContent: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '14px',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  messageTime: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '10px',
  },
  lightThemeText: {
    color: "#222222!important"
  }
})

const EmojiPopover = styled(Popover)({
  '& .emoji-mart-search': {
    '& input': {
      borderColor: '#ae65c5',
    },
  },

  '& .emoji-mart-anchor': {
    cursor: 'pointer',
  },

  '& .emoji-mart-emoji': {
    '& span': {
      cursor: 'pointer !important',
    }
  },
})

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    },
    info: {
      main: "#222222",
    },
    warning: {
      main: "#78716C",
    },
   }
});

const commonDarkTheme=createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3858E3",
    },
    secondary:{
    main:"rgba(255,255,255,0.75)"
    },
    info: {
      main: "#FFFFFF",
    },
    warning: {
      main: "#78716C",
    },
   }
  });

export const ConversationContent = withStyles(styles)(ViewChat)
// Customizable Area End
