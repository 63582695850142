import { profile } from "../../blocks/chat/src/assets";
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { Box, Button, styled, Typography } from "@material-ui/core";
import { CometChatUIKitConstants } from "@cometchat/chat-uikit-react";
import CometChatBubbleViewReaction from "./CometChatBubbleViewReaction";
import CometChatBubbleViewListReaction from "./CometChatBubbleViewListReaction";
import { addReactionToMessage, fetchListReactionByMessage, ReactionItem, ReactionObject, removeReactionToMessage } from "./CometChat";
import { convertTimestampToTime, emitterEvents } from "./utils";
import { EventSubscription } from "fbemitter";

interface IPropCometChatBubbleViewTemplate {
    message: CometChat.BaseMessage,
    typeTemplate: string
    userInfo: CometChat.User | null;
    categories: string;
    checked?: boolean;
}

interface IPropMessageRenderer {
    message: CometChat.BaseMessage;
    categories?: string
}

function CometChatBubbleViewTemplate({ message, typeTemplate, userInfo, categories, checked }: IPropCometChatBubbleViewTemplate) {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [currentListReaction, setCurrentListReaction] = useState<ReactionObject[]>([]);

    let subscription: EventSubscription | null = null;
    const currentUserLoginUid = userInfo?.getUid() || "";
    const { uid: senderMessageUid = "", avatar = "" } = message?.getSender() as unknown as { uid: string, avatar: string };
    const messageId = message?.getId();
    const timestampSentAt = convertTimestampToTime(message.getSentAt());
    const isMessageSentByCurrentUser = checkIsMessageSentByCurrentUser(senderMessageUid, currentUserLoginUid);

    useEffect(() => {
        // Get all Reaction of this message
        const getReactionsByMessage = async () => {
            const listReaction = await fetchListReactionByMessage(messageId);
            setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
        }
        getReactionsByMessage();

        subscription = emitterEvents.addListener('messageIdReaction', async (messageIdReaction: string) => {
            if (messageIdReaction) {
                if (messageId.toString() !== messageIdReaction) return;
                const listReaction = await fetchListReactionByMessage(Number(messageIdReaction));
                setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
            }
        })

        return () => {
            if (subscription) {
                subscription.remove()
            }
        }
    }, [])

    const handleRenderMessageByType = (message: CometChat.BaseMessage, typeTemplate: string) => {
        let messageRenderer = null;
        switch (typeTemplate) {
            case CometChatUIKitConstants.MessageTypes.text:
                messageRenderer = <MessageTextRenderer message={message} />
                break;
            case CometChatUIKitConstants.MessageTypes.image:
                messageRenderer = <MessageImageRenderer message={message} />
                break;
            case CometChatUIKitConstants.MessageTypes.file:
                messageRenderer = <MessageFileRenderer message={message} />
                break;
            default:
                break;
        }
        return messageRenderer;
    }

    const handleAddReactionToMessage = async (messageId: number, emoji: string) => {
        try {
            await addReactionToMessage(messageId, emoji);
            const listReaction = await fetchListReactionByMessage(messageId);
            setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
        }
        catch (error) {
            console.error("Send reaction fail", error)
        }
    }

    const handleRemoveReactionToMessage = async (messageId: number, emoji: string) => {
        try {
            await removeReactionToMessage(messageId, emoji);
            const listReaction = await fetchListReactionByMessage(messageId);
            setCurrentListReaction(summarizeReactions(listReaction, currentUserLoginUid));
        }
        catch (error) {
            console.error("Send reaction fail", error)
        }
    }

    return (
        categories === CometChatUIKitConstants.MessageCategory.action
            ? <MessageActionMemberRenderer message={message} categories={categories} />
            : <CometChatBubbleViewTemplateStyled  checked={checked}>
                <Box className="messageReactionContainer" onMouseEnter={() => { setIsMouseOver(!isMouseOver) }} onMouseLeave={() => setIsMouseOver(!isMouseOver)}>
                    <Box className="messageContainer">
                        {!isMessageSentByCurrentUser && <img style={{ borderRadius: "30px" }} src={avatar || profile} width={30} height={30} />}
                        <Box className={`contentMessage ${clsx({
                            'user-message': isMessageSentByCurrentUser,
                            'other-message': !isMessageSentByCurrentUser,
                        })}`}>
                            {handleRenderMessageByType(message, typeTemplate)}
                            <Box className="messageSentAt">
                                <span style={{color: checked ? "#222222" : "#FFFFFF"}}>{timestampSentAt}</span>
                            </Box>
                        </Box>
                    </Box>
                    <CometChatBubbleViewReaction
                        messageId={messageId}
                        isMouseOver={isMouseOver}
                        onAddReactionToMessage={handleAddReactionToMessage}
                    />
                </Box>
                <CometChatBubbleViewListReaction
                    checked={checked}
                    message={message}
                    listReaction={currentListReaction}
                    onRemoveReactionToMessage={handleRemoveReactionToMessage}
                    isMessageSentByCurrentUser={isMessageSentByCurrentUser}
                />
            </CometChatBubbleViewTemplateStyled>
    )
}

export function summarizeReactions(dataArray: ReactionItem[], myUid: string): ReactionObject[] {
    const summaryMap: Record<string, { count: number; reactedByMe: boolean }> = {};

    dataArray.forEach((item) => {
        const reaction = item.reaction;

        if (!summaryMap[reaction]) {
            summaryMap[reaction] = {
                count: 0,
                reactedByMe: false
            };
        }

        summaryMap[reaction].count += 1;
        if (item.uid === myUid) {
            summaryMap[reaction].reactedByMe = true;
        }
    });

    // Convert the summaryMap into an array of ReactionSummaryItem
    const summary: ReactionObject[] = Object.entries(summaryMap).map(([reaction, { count, reactedByMe }]) => ({
        reaction,
        count,
        reactedByMe
    }));

    return summary;
}

const checkIsMessageSentByCurrentUser = (senderUidMessage: string, currentUserLoginUid: string) => {
    return currentUserLoginUid === senderUidMessage;
}

const MessageTextRenderer = ({ message }: IPropMessageRenderer) => {
    const getDataMessage = message.getData();
    const { text } = getDataMessage as unknown as { text: string };
    return <Typography className="messageTextContent" component={"p"}>
        {text}
    </Typography>
}

const MessageImageRenderer = ({ message }: IPropMessageRenderer) => {
    const getDataMessage = message.getData();
    const { url } = getDataMessage as unknown as { url: string };
    return (
        <Box>
            <img src={url} alt={"i"} style={{
                maxWidth: "100%",
                maxHeight: "100%",
                display: "block",
                margin: "auto"
            }} />
        </Box>
    )
}

const MessageFileRenderer = ({ message }: IPropMessageRenderer) => {
    const getDataMessage = message.getData();
    const { attachments = [] } = getDataMessage;
    const getAttachment: { name: string, url: string } = attachments.find((attachment: { name: string, url: string }) => attachment.name);
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = getAttachment.url;
        link.download = getAttachment.name;
        link.dispatchEvent(new MouseEvent('click'));
    };
    return (
        <Box>
            <p className="messageTextContent">{getAttachment.name}</p>
            <Button className="buttonDownloadFile" onClick={handleDownload}>
                Download
            </Button>
        </Box>
    )
}

const MessageActionMemberRenderer = ({ message, categories = "" }: IPropMessageRenderer) => {
    const castMessage = message as unknown as { message: string };
    return (
        <MessageActionRendererStyled>
            <p className="messageTextAction">{castMessage.message}</p>
        </MessageActionRendererStyled>
    )
}

const MessageActionRendererStyled = styled(Box)({
    padding: "6px 10px",
    background: "#7575751F",
    borderRadius: "6px",
    "& .messageTextAction": {
        fontFamily: "Manrope",
        fontWeight: 500,
        fontSize: "10px",
        color: "#757575"
    }
});

const CometChatBubbleViewTemplateStyled = styled(Box)(({ checked }: { checked?: boolean }) => ({
    width: "100%",
    display: "flex",
    gap: 10,
    flexDirection: "column",
    position: "relative",
    "& .messageContainer": {
        display: "flex",
        gap: 5,
        width: "100%",
        "& .contentMessage": {
            width: "100%",
            boxSizing: "border-box",
            minHeight: 60,
            padding: 16,
            borderRadius: 16,
            "& .messageTextContent": {
                wordBreak: "break-all",
                whiteSpace: "normal"
            },
            "& .buttonDownloadFile": {
                padding: "6px 0"
            },
            "& .messageSentAt": {
                textAlign: "right",
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontWeight: 500,
                fontSize: "10px"
            }
        },
        "& .contentMessage.user-message": {
            background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)"
        },

        "& .contentMessage.other-message": {
            background: checked ? "#E9E9E9" : "#222222"
        },
    } 
}));

export default CometChatBubbleViewTemplate;