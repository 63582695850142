import React from 'react';
import {
  Box,
  Dialog,
  Grid,
  Typography,
  styled,
  Button,
  ThemeProvider,
  createTheme,
} from '@material-ui/core';

interface MuteProps {
  checked: boolean;
  isOpen: boolean;
  onSubmit:()=> void;
  onClose: ()=> void;
  userName: string;
  isBlock: boolean;
}

const BlockModal: React.FC<MuteProps> = (props) => {

  const conditionRemover = (
    condition: boolean,
    trueStatement: string,
    falseStatement: string
  ) => {
    return condition ? trueStatement : falseStatement;
  };


  // Renders the main mute confirmation modal
  const renderModal = () => (
    <DialogBox
      data-test-id="muteModel"
      onClose={() => props.onClose()}
      open={props.isOpen}
      PaperProps={{
        className: conditionRemover(
          props.checked,
          'modelDarkStyle',
          'modelLightStyle'
        ),
      }}
    >
      <GridContainer container justifyContent="center" alignItems="center" direction="column">
        <Box style={{paddingBottom:12,borderBottom:"1px solid #222222"}}>
          <Typography className="name">{props.isBlock?"Unblock":"Block"} @{props.userName}</Typography>
        </Box>
        <Box>
          <Typography className="subhead">
          Are you sure you want to {props.isBlock?"unblock":"block"}<br/>
                @{props.userName}
          </Typography>
        </Box>
        <Box className="muteAction">
          <Button
            onClick={() => props.onClose()}
            data-test-id="noBtn"
            className={conditionRemover(!props.checked, "muteBtnDeny", "muteBtnDenyDark")}
          >
            <Typography className="showmore">No</Typography>
          </Button>
          <Button
            onClick={() => props.onSubmit()}
            data-test-id="muteagree"
            className="muteBtn"
          >
            {props.isBlock?"Unblock":"Block"}
          </Button>
        </Box>
      </GridContainer>
    </DialogBox>
  );

  return (
    <ThemeProvider theme={!props.checked?commonLightTheme:commonDarkTheme}>
      <BoxContainer>
        {renderModal()}
      </BoxContainer>
    </ThemeProvider>
  );
};

// Styled Components
const BoxContainer = styled(Box)({
});

const DialogBox = styled(Dialog)({
  '& .bottomModelDarkStyle': {
    borderRadius: '6px',
    backdropFilter: 'blur(1px)',
    boxShadow: 'none',
    position: 'absolute',
    top: '80%',
  },
  '& .modelDarkStyle': {
    borderRadius: '10px',
    backdropFilter: 'blur(1px)',
    background: 'linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)',
    boxShadow: 'none',
  },
  '& .modelLightStyle': {
    borderRadius: '10px',
    backdropFilter: 'blur(1px)',
    background: 'linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)',
    boxShadow: 'none',
  },
  '& .bottomModelStyle': {
    borderRadius: '6px',
    backdropFilter: 'blur(1px)',
    boxShadow: 'none',
    position: 'absolute',
    top: '80%',
  },
});

const GridContainer = styled(Grid)({
  padding: 20,
  rowGap: '24px',
  width: '375px',
  border:"1px solid #35383F",
  borderRadius:10,
  '& .name': {
    fontSize: '18px',
    fontFamily: 'Manrope',
    fontWeight: 800,
    lineHeight: '21px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& .subhead': {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight:"19.2px",
    textAlign: 'center',
  },
  '& .muteBtn': {
    boxShadow: 'none !important',
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    borderRadius: '10px',
    padding: '16px',
    width: '160px',
    height: '48px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily:"Manrope"
  },
  '& .muteBtnDeny': {
    boxShadow: 'none !important',
    background: 'linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 , #BA05F7)',
    borderRadius: '10px',
    padding: '16px',
    width: '160px',
    height: '48px',
  },
  '& .muteBtnDenyDark': {
    boxShadow: 'none !important',
    background: 'linear-gradient(to right, #0d0c0c, #0d0c0c),linear-gradient(to right, #3858E3 , #BA05F7)',
    borderRadius: '10px',
    padding: '16px',
    width: '160px',
    height: '48px',
    border:"1px solid #6938eb",
  },
  '& .showmore': {
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '21px',
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '& .muteAction': {
    display: 'flex',
    columnGap: '12px',
  },
});

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary:{
      main:'rgba(0,0,0,0.75)'
    },
    info: {
      main: "#222222",
    },
    warning: {
      main: "#78716C",
    },
   },
});

const commonDarkTheme=createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3858E3",
    },
    secondary:{
    main:"rgba(255,255,255,0.75)"
    },
    info: {
      main: "#FFFFFF",
    },
    warning: {
      main: "#78716C",
    },
   },
  });

export default BlockModal;
