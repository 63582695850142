Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "bx_block_notifications/notifications?";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod="PATCH";
exports.httpDeleteMethod="DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.savePostEndpoint ="bx_block_posts/save_post"
exports.unSavePostEndpoint ="bx_block_posts/unsave_post"
exports.likePostEndpoint ="bx_block_like/likes";
exports.mentionNotifictionEndPoint ="bx_block_notifications/notifications/mentions"


exports.showMore = "Show more";
exports.whoToFollow ="Who to follow"
exports.Post="Post"
exports.subscribeToUnlock ="Subscribe to unlock new features and if eligible, receive a share of ads revenue."
exports.chooseSubscriptionText ="Choose the right subscription for you!"
exports.Follow ="Follow"
// Customizable Area End