import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import React, { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Post } from "../../activityfeed/src/ActivityFeedController";


export interface FollowersData {
  name:string,
  username:string,
  userProfile:string
 }

 export interface Media {
  id: number;
  url: string;
  thumbnail_url: string | null;
  filename: string;
  content_type: string;
  file_size: number;
}

export interface User {
  id: number;
  full_name: string;
  user_name: string;
  email: string | null;
  full_phone_number: string;
  country_code: string;
  phone_number: number;
  bio: string | null;
  location: string | null;
  website: string | null;
  occupation: string | null;
  created_at: string;
  is_early_adopter: boolean;
  date_of_birth: string | null;
  admin_verification: boolean;
  profile_photo: string | null;
  cover_photo: string | null;
  is_following: boolean;
  is_follower: boolean;
  is_muted: boolean;
  is_blocked: boolean;
  following_count: number;
  followers_count: number;
  user_subscription: string;
  user_ownership: boolean;
  is_tailored: boolean;
  stories_count: number;
  viewed_stories: number;
}

export interface BodyMention {
  id: number;
  full_name: string;
  user_name: string;
  indices: [number, number];
}

export interface HashTag {
  hash_tag: string;
  indices: [number, number];
}

export interface MentionedData {
  id: string;
  type: string;
  attributes: {
  reposted_by: string | null;
  id: number;
  connected_post_id: number | null;
  is_repost: boolean;
  body: string;
  body_mentions: BodyMention[];
  hash_tags: HashTag[];
  media: Media[];
  media_mentions:string[];
  location: string | null;
  created_at: string;
  updated_at: string;
  is_saved: boolean;
  is_pinned: boolean;
  is_liked: boolean;
  total_likes: number;
  total_reposts: number;
  total_comments: number;
  impressions: number;
  model_name: string;
  post_by: User;
  mentioned_accounts: string[];
  parent_post: null;
  }
}

interface PaginationDetails {
  page_number: number;
  records_in_this_page: number;
  records_per_page: number;
  total_pages: number;
  total_records: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:{[key:string]:string}
  checked?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  followers:FollowersData[],
  filter:string,
  activeIndex:number,
  filterModel:boolean,
  filterOption:string[],
  selectedItems:number,
  mentionData:Post[],
  page:number,
  activePostId:string,
  pagination:PaginationDetails,
  showComments:boolean,
  selectedPostComment: Post|undefined
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  getMentionedPostApiCallId:string="";
  markAsReadCallId: string = "";
  savePostApiCallId:string="";
  deleteCallId: string = "";
  likeUnLikedPostApiCallId:string="";
  scrollParentRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      followers:[{name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""},{name:"ralhps vibes",username:"@ralhps",userProfile:""},
      {name:"ralhps vibes",username:"@ralhps",userProfile:""}
      ],
      filter:"",
      activeIndex:0,
      filterModel:false,
      selectedItems:2,
      filterOption:[
        "Accounts you follow",
        "Accounts you don't follow",
        "New users on the platform"
      ],
      mentionData:[],
      activePostId:'',
      page:1,
      pagination:{
        page_number: 0,
        records_in_this_page: 0,
        records_per_page: 0,
        total_pages: 0,
        total_records: 0 
      },
      selectedPostComment:undefined,
      showComments:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId =  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } 
    else{
      this.handleResponse(apiRequestCallId,message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  getNotifications =async()=> {
    const token = await getStorageData("authToken");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint+`?page=${this.state.page}&per_page=10`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  getMentionedPostList=async()=>{
    const token = await getStorageData("authToken");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const httpHeader ={
      "Content-Type": configJSON.apiContentType,
      token: token,
    }
    this.getMentionedPostApiCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.mentionNotifictionEndPoint+`?page=${this.state.page}&per_page=10`);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(httpHeader));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.getDataMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  handleLikePost = async (id: string, type: string, likeable_type: string) => {
    this.setState({ activePostId:id})
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };

    let httpBody = {
      likeable_id: id,
      likeable_type: likeable_type
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeUnLikedPostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.likePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type == "like" ? configJSON.httpPostMethod : configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleSavePost = async(id:string,type:string) => {
    let authToken = await getStorageData("authToken")
    const header = {
      token: authToken,
      "Content-Type": configJSON.apiContentType,
    };

    let httpBody = {
      post_id:id,    
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savePostApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),type=="save"?configJSON.savePostEndpoint:configJSON.unSavePostEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), type=="save"?configJSON.httpPostMethod:configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  setFilter=((event:React.ChangeEvent<HTMLInputElement>)=> {    
    this.setState({ filter: event.target.value })
  })

  handleOpenCommentModal=(postId:string)=>{
    this.setState((prev) => {
      const selectedPost = prev.mentionData?.find(post => post.id === postId)
      return {
        ...prev, 
        showComments: true,
        selectedPostComment: selectedPost
      }
    })
  }

  handleCloseCommentsModal = () => {
    this.setState({showComments: false, selectedPostComment: undefined})
  }

  handleCommentFunctions=()=>{
    return 
  }

  handleTabsChange = (event: ChangeEvent<{}>, newValue: number) => {    
    this.setState({ activeIndex: newValue,
      mentionData:[],
      page:1,
      pagination:{
        page_number: 0,
        records_in_this_page: 0,
        records_per_page: 0,
        total_pages: 0,
        total_records: 0 
      }
    },this.handleApi)
   };

   handleApi=()=>{
    if(this.state.activeIndex===0){
      this.getNotifications()
    }else{
      this.getMentionedPostList()
    }
   }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    console.log(id);
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  handleClose =()=>{
    this.setState({filterModel:false})
  }

  handleOpenModel =()=>{
    this.setState({filterModel:true})
  }

  handleItemClick = (index:number) => {
    this.setState({ selectedItems: index });
  };
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: number) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  handleNotificationResponse=(message:Message)=>{
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: [...this.state.data,...apiResponse?.notifications?.data],
          pagination:apiResponse?.pagination_details,
          page:this.state.page+1
        });
  }
  
  handleMentionedlistResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(!apiResponse?.errors){ 
      this.setState((prev)=>({
      mentionData: [...prev.mentionData,...apiResponse.data],
      page:prev.page+1,
      pagination:apiResponse.pagination_details
     }))
    }
  }
  
  handleSavePostResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(!apiResponse?.errors){
      if (apiResponse && apiResponse?.data) {
        let newfeeds = [...this.state.mentionData]
        let feeds = newfeeds.map(post => {
          if (post.attributes.id == apiResponse.data.id) {
            return {
                   ...post,
                   attributes: {
                     ...post.attributes,
                     is_saved: apiResponse.data.attributes.is_saved
                   }
                 };
               }
               return post;
         });

         this.setState({mentionData:feeds})
    }
  }
  }


  handleLikeUnLikePostResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(!apiResponse?.errors){
      if(apiResponse && apiResponse?.messages) {
        let feeds = [...this.state.mentionData]
        let newarray = feeds.map(post => {
          if (post.id === this.state.activePostId) {
            return {
                   ...post,
                   attributes: {
                     ...post.attributes,
                     is_liked:  apiResponse.messages[0]=="Liked Successfully"?true:false,
                     total_likes:apiResponse.messages[0]=="Liked Successfully"?post.attributes.total_likes+1:post.attributes.total_likes-1
                   }
                 };
               }
               return post;
         });

         this.setState({mentionData:newarray})    
      }
    }
   
}

  handleMarkAsReadResponse=(message:Message)=>{
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    apiResponse && this.getNotifications();
  }

  handleDeleteNotificationResponse=(message:Message)=>{
       const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
      this.getNotifications();
  }

  handleResponse=(apiRequestCallId:string,message:Message)=>{
    switch(apiRequestCallId){
      case this.getDataCallId:
        return this.handleNotificationResponse(message)
      case this.markAsReadCallId:
        return this.handleMarkAsReadResponse(message)
      case this.deleteCallId:
        return this.handleDeleteNotificationResponse(message)  
      case this.getMentionedPostApiCallId:
        return this.handleMentionedlistResponse(message)
      case this.likeUnLikedPostApiCallId: 
        return this.handleLikeUnLikePostResponse(message)
      case this.savePostApiCallId:
        return this.handleSavePostResponse(message)
    }
  }
  // Customizable Area End
}
